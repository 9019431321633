import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from '../store/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faSearchLocation,
  faExclamationTriangle,
  faEnvelope,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import ReactExport from 'react-export-excel';
import './SearchBuilding.css';
import AWS from 'aws-sdk'
import swal from "sweetalert";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Alerte = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const onAlerteToggle = () => dispatch(actions.toggleAlerte());
  const alerte = useSelector(state => state.alerte);
  const dataExport = useSelector(state => state.dataExport);

  const app = useSelector(state => state.app);
  let disableButton;
  if (app === "AS" || app === "ASN" || app === "AS_NDPJ" || app === "AS_SCB" || app === "RI_M") {
    disableButton = true
  } else {
    disableButton = false
  }

  const sms = () => {
    swal({
      title: "Êtes-vous certain de vouloir envoyer les alertes par SMS?",
      text: "Des frais supplémentaires s'appliqueront selon le nombre de SMS envoyés.",
      icon: "warning",
      buttons: ["Annuler", "Envoyer"],
      dangerMode: true,
    })
      .then((willSendAlert) => {
        if (willSendAlert) {
          //Set AWS config
          AWS.config.update({
            accessKeyId: "AKIARPHK5VNFZ3RCHZYM",
            secretAccessKey: "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",
            region: 'us-east-1'
          });
          console.log(dataExport);
          // let sns = new AWS.SNS();
          // for (let i = 0; i < dataExport.length; i++) {
          // // for (let i = 0; i < 1; i++) {
          //   let params = {
          //     Message: "Ce message d'alerte vous a été envoyé par Geosapiens puisque votre propriété a une probabilité d'inondation très élevée dans les trois prochains jours.",
          //     MessageStructure: 'string',
          //     // PhoneNumber: '+4182713266'
          //     // PhoneNumber: '+5144586369'
          //     PhoneNumber: dataExport[i].telephone  //arranger le format
          //   };
          //   sns.publish(params, function (err, data) {
          //     if (err) console.log(err, err.stack); // an error occurred
          //     else console.log("successful response: ", data); // successful response
          //   });
          //   console.log("Sms sent to " + dataExport[i].telephone);
          //   // console.log("Sms sent to " + params.PhoneNumber);
          // }
          let sendAlertText = "Les " + dataExport.length + " alertes par SMS ont été envoyés!";
          swal(sendAlertText, {
            icon: "success",
          });
        } else {
          swal("Les alertes par SMS n'ont pas été envoyés", {
            icon: "error",
          });
        }
      });
  };

  const email = () => {
    swal({
      title: "Êtes-vous certain de vouloir envoyer les alertes par courriel?",
      text: "Des frais supplémentaires s'appliqueront selon le nombre de courriels envoyés.",
      icon: "warning",
      buttons: ["Annuler", "Envoyer"],
      dangerMode: true,
    })
      .then((willSendAlert) => {
        if (willSendAlert) {
          // Set AWS config
          AWS.config.update({
            accessKeyId: "AKIARPHK5VNFU5LKYQYX",
            secretAccessKey: "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",
            region: 'ca-central-1'
          });
          // Create list of emails
          let emailArray = [];
          for (let i = 0; i < dataExport.length; i++) {
            // for (let i = 0; i < 1; i++) {
            emailArray.push(dataExport[i].courriel);
            // emailArray.push("ctousignant84@gmail.com"); //for test
          }
          // emailArray = ["ctousignant84@gmail.com", "chuk84@hotmail.com"]; //for test
          console.log(emailArray);
          let sendAlertText = "Les " + emailArray.length + " alertes par courriel ont été envoyés!";
          // Create sendEmail params
          let params = {
            Destination: { /* required */
              // CcAddresses: [
              //   'ctousignant84@gmail.com',
              //   /* more items */
              // ],
              ToAddresses: emailArray
            },
            Message: { /* required */
              Body: { /* required */
                Html: {
                  Charset: "UTF-8",
                  Data: "" +
                    "<div><p>Ce courriel d'alerte vous a été envoyé puisque votre propriété a une probabilité d'inondation très élevée dans les trois prochains jours.</p></div><br>" +
                    "<img src='https://webapp-public.s3.ca-central-1.amazonaws.com/logo-e-nundation_transparent.png'><br>" +
                    "<img src='https://webapp-public.s3.ca-central-1.amazonaws.com/geosapiens.png'>"
                },
                // Text: {
                //   Charset: "UTF-8",
                //   Data: "TEXT_FORMAT_BODY"
                // }
              },
              Subject: {
                Charset: 'UTF-8',
                Data: "Alerte d'inondation imminente"
              }
            },
            Source: 'E-nundation <info@geosapiens.ca>', /* required */
            // ReplyToAddresses: [
            //   'ctousignant84@gmail.com',
            //   /* more items */
            // ],
          };
          //Create the promise and SES service object
          // let sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();
          // //Handle promise's fulfilled/rejected states
          // sendPromise.then(
          //   function (data) {
          //     console.log(data.MessageId);
          //   }).catch(
          //   function (err) {
          //     console.error(err, err.stack);
          //   });
          swal(sendAlertText, {
            icon: "success",
          });
        } else {
          swal("Les alertes par courriel n'ont pas été envoyés", {
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      {/*<div id="alertDiv" style={{display: isOpen ? 'block' : 'none'}}>*/}
      <div id="alertDiv" style={{display: alerte ? 'block' : 'none'}}>
        <p className="title-recherche">Envoyer une alerte</p>
        <button data-title="sms" className="btn-icon" id="smsBtn" onClick={sms}>
          <FontAwesomeIcon icon={faMobileAlt} size={"lg"}/>
        </button>
        <button data-title="courriel" className="btn-icon" id="courrielBtn" onClick={email}>
          <FontAwesomeIcon icon={faEnvelope} size={"lg"}/>
        </button>
      </div>
      {/*<button data-title="Alerter" className="btn-icon" id="alertBtn" onClick={toggle}>*/}
      <button data-title="Alerter" className="btn-icon" id="alertBtn" disabled={disableButton} onClick={onAlerteToggle}>
        <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"}/>
      </button>
    </>
  )
};

const Download = (props) => {
  const dataExport = useSelector(state => state.dataExport);
  const app = useSelector(state => state.app);
  const currentScenario = useSelector(state => state.scenario);
  const debit = useSelector(state => state.debit);

  let appDict = {"AS": "Assomption", "ASN": "Assomption", "AS_NDPJ": "Assomption", "AS_SCB": "Assomption", "DM": "DeuxMontagnes", "RI": "Rigaud", "RI_M": "Rigaud", "MI": "MilleIles", "CD": "Chaudiere", "CH_M": "Champlain"};
  let riviere = "";
  for (let key in appDict) {
    if (key === app) {
      riviere = appDict[key]
    }
  }
  let scenario = props.labels[currentScenario];
  let unitMesure = "m3s";
  if (debit===false) {
    unitMesure = "m"
  }
  let filename = "E-nundation - " + riviere + " - " + scenario + " " + unitMesure;

  return (
    <ExcelFile element={<button data-title="Exporter (XLS)" className="btn-icon" id="pdfBtn"><FontAwesomeIcon
      icon={faFileExport} size={"lg"}/></button>} filename={filename}>
      <ExcelSheet data={dataExport} name="État des bâtiments">
        <ExcelColumn label="No civique" value="no_civ"/>
        <ExcelColumn label="Rue" value="rue"/>
        <ExcelColumn label="État" value="etat"/>
        <ExcelColumn label="Hauteau d'eau" value="h_eau_ter"/>
        <ExcelColumn label="Sacs de sable" value="nb_sacs"/>
        {/*<ExcelColumn label="Volume de sable" value="volume"/>*/}
        {/*<ExcelColumn label="Personnes à évacuer" value="nb_person"/>*/}
        <ExcelColumn label="Personnes à évacuer" value=""/>
        {/*<ExcelColumn label="Courriel" value="courriel"/>*/}
        <ExcelColumn label="Courriel" value=""/>
        {/*<ExcelColumn label="Téléphone" value="telephone"/>*/}
        <ExcelColumn label="Téléphone" value=""/>
        {/*<ExcelColumn label="Scénario" value="scenario"/>*/}
      </ExcelSheet>
    </ExcelFile>)
};

export const SearchBuilding = (props) => {
  // const dispatch = useDispatch();
  // const onManualModeToggle = () => dispatch(actions.toggleManualMode());
  // const manualMode = useSelector(state => state.manualMode);
  const search = useSelector(state => state.search);
  return (
    <div className="esri-widget" id="searchDiv" style={{display: search ? 'block' : 'none'}}>
      <p className="title-recherche">Recherche de bâtiments</p>
      <div className="recherche_container">
        <select className="esri-widget"
                id="attSelect"
                defaultValue={"'Isolé (route inondée)' OR ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé' OR ETAT = 'Eau atteint le bâtiment'"}>
          <option
            value="'Isolé (route inondée)' OR ETAT = 'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'
            OR ETAT = 'Eau atteint le bâtiment'">À risque
          </option>
          <option value="'Isolé (route inondée)'">Isolé</option>
          {/*<option*/}
          {/*  value="'Sous-sol inondé' OR ETAT = 'Eau atteint le bâtiment' OR ETAT = 'Sous-sol et premier plancher inondé'">*/}
          {/*  Sous-sol inondé*/}
          {/*</option>*/}
          {/*<option value="'Sous-sol et premier plancher inondé'">1er plancher inondé</option>*/}
          <option
            value="'Sous-sol inondé' OR ETAT = 'Sous-sol et premier plancher inondé'
            OR ETAT = 'Eau atteint le bâtiment'">Inondé
          </option>
        </select>
        <button data-title="Rechercher" className="btn-icon" id="searchBtn">
          <FontAwesomeIcon icon={faSearchLocation} size={"lg"}/>
        </button>
        <Download labels={props.labels}/>
        <Alerte/>
        {/*<button*/}
        {/*  id="btnManualMode"*/}
        {/*  // className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}*/}
        {/*  onClick={onManualModeToggle}*/}
        {/*>*/}
        {/*  mode manuel*/}
        {/*</button>*/}
        {/*<div id="div-manual"  style={{display: manualMode ? 'block' : 'none'}}/>*/}
      </div>
      <p className="print_results_container"><span id="printResults"/></p>
    </div>)
};